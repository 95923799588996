.game-container {
    box-sizing: border-box;
    border-radius: 12px;
    background-color: #dce1eb;
    width: 100%;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;

    .game-log-other {
        // overflow: auto;
        height: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;

        .play-wrap {
            box-sizing: border-box;
            width: 1090px;
            background-color: #fafafa;
            border-radius: 12px;
            font-size: 14px;

            .game-info {
                position: relative;
                overflow: hidden;
                height: 166px;
                background-image: url(../../images//infobgxoso.png);
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: contain;
                color: #444e57;
                display: flex;

                .game-name {
                    width: 315px;
                    line-height: 1.3;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    h3 {
                        font-size: 18px;
                        font-weight: 600;
                        font-family: Arial, Helvetica, sans-serif;
                    }

                    p {
                        font-size: 16px;
                        margin-top: 5px;
                    }

                    .img-box {
                        overflow: hidden;
                        margin-right: 20px;
                        width: 102px;

                        height: 102px;
                        border-radius: 50%;
                    }
                }

                .game-num {
                    position: relative;
                    width: 414px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    font-family: Arial, Helvetica, sans-serif;

                    &:before {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        display: block;
                        width: 0;
                        height: 102px;
                        border-left: 1px solid #c7d0df;
                        content: '';
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }

                    > span {
                        font-size: 16px;
                    }

                    .balls {
                        display: flex;
                        margin-bottom: 15px;

                        .ball {
                            margin: 0 10px;
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            color: #fff;
                            text-align: center;
                            font-weight: 700;
                            font-size: 28px;
                            line-height: 50px;
                            color: #3a81e5;
                            position: relative;

                            span {
                                width: 100%;
                                height: 100%;
                                background-color: #fafafa;
                                background-repeat: no-repeat;
                                background-blend-mode: hard-light;
                                background-size: contain;
                                position: relative;
                                z-index: 10;
                                display: block;
                                border: 0.8px solid #bfc7d2;
                                border-radius: 100%;
                            }

                            &:after {
                                box-sizing: border-box;
                                position: absolute;
                                display: block;
                                content: '';
                                width: 100%;
                                height: 10px;
                                background-image: url(../../images/bong_mo_so.png);
                                background-size: contain;
                                background-position: 50%;
                                z-index: 0;
                                margin-top: -4px px;
                            }
                        }
                    }
                }

                .pc-game-time {
                    width: 354px;
                    font-family: Roboto, sans-serif;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    > span {
                        font-size: 16px;
                    }

                    &:before {
                        position: absolute;
                        top: 50%;
                        left: 0;
                        display: block;
                        width: 0;
                        height: 102px;
                        border-left: 1px solid #c7d0df;
                        content: '';
                        -webkit-transform: translateY(-50%);
                        transform: translateY(-50%);
                    }

                    .count {
                        margin-top: 14px;
                        height: 44px;
                        color: #ea524d;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 44px;
                        display: flex;

                        .item {
                            span {
                                display: inline-block;
                                margin: 0 1px;
                                min-width: 32px;
                                height: 44px;
                                border-radius: 4px;
                                text-align: center;
                                letter-spacing: normal;
                                font-weight: 700;
                                font-style: normal;
                                font-stretch: normal;
                                font-size: 28px;
                                background-image: url(../../images/bg-item-xoso.png);
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }

                        .dot {
                            margin: 14px 2px;
                            width: 5px;
                            height: 16px;
                            color: #ea524d;
                            text-align: left;
                            letter-spacing: normal;
                            font-weight: 400;
                            font-style: normal;
                            font-stretch: normal;
                            font-size: 28px;
                            font-family: PingFangSC;
                            line-height: 2.86;

                            &:before {
                                margin-bottom: 6px;
                            }

                            &:after,
                            &:before {
                                display: block;
                                width: 5px;
                                height: 5px;
                                border-radius: 50%;
                                background-color: #ea524d;
                                content: '';
                            }
                        }
                    }
                }
            }
        }
    }

    .history-wrap {
        // overflow: auto;
        min-height: 756px;
        width: 300px;
        background-color: #f5f5f5;
        border-radius: 12px;
    }
}

.play-type {
    .cates {
        position: relative;
        height: 35px;
        line-height: 35px;
        background: #eef2f8;

        .cates_box {
            width: 100%;
            overflow: hidden;
            height: 100%;
            position: relative;

            .cates_list {
                width: auto;
                height: 100%;
                position: absolute;
                transition: all 0.28s ease-in-out;
                left: 0;

                .item {
                    position: relative;
                    display: inline-block;
                    height: 33px;
                    margin-top: 2.5px;
                    padding-left: 20px;
                    padding-right: 20px;
                    color: #3e4558;
                    font-size: 14px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    font-weight: 400;
                    transition: all 0.3s ease-in-out;
                    border-top-right-radius: 6px;
                    border-top-left-radius: 6px;
                }

                .item.active,
                .item:hover {
                    color: #3a81e5;
                    background: linear-gradient(180deg, #f7f8fb -7.69%, #fafafa 103.26%);
                    box-shadow: 0 -6px 12px #dae0ea, inset -1px 0 0 #fff;
                }

                .item.hot:after {
                    position: absolute;
                    top: -2px;
                    right: -2px;
                    display: block;
                    width: 16px;
                    height: 16px;
                    background-image: url(../../images/iconhot.png);
                    background-size: 100% auto;
                    content: '';
                    z-index: 10;
                }
            }
        }

        .rule {
            position: absolute;
            top: 0;
            right: 0;
            overflow: hidden;
            margin-right: 21px;
            cursor: pointer;
            color: #444e57;

            .rule-block {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            span {
                margin: 2px 0 0 5px;
            }

            img {
                width: 16px;
                height: 16px;
            }
        }
    }

    .plays-tabs {
        padding: 14px 0 4px;
        margin: 0 20px 0 21px;
        min-height: 51px;
        display: flex;
        box-sizing: border-box;
        align-items: flex-start;
        justify-content: space-between;

        .plays {
            flex-shrink: 1;
            flex-grow: 0;
            width: auto;

            .list {
                height: 28px;
                max-width: 635px;

                .item {
                    position: relative;
                    float: left;
                    margin-right: 15px;
                    margin-bottom: 10px;
                    padding: 0 10px;
                    height: 28px;
                    background: #eef2f8;
                    border-radius: 4px;
                    color: #444;
                    font-size: 13px;
                    line-height: 28px;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    transition: all 0.3s ease-in-out;
                }

                .item.active {
                    background: #3d8bfa;
                    color: #fff;
                    transition: all 0.3s ease-in-out;
                }

                .item.hot:after {
                    position: absolute;
                    top: -8px;
                    right: -8px;
                    display: block;
                    width: 16px;
                    height: 16px;
                    background-image: url(../../images/iconhot.png);
                    background-size: 100% auto;
                    content: '';
                    z-index: 10;
                }
            }
        }

        .game-tabs {
            border-radius: 4px;

            .item-warp {
                background-color: #f4f6fb;
                box-shadow: inset 0.8px 0 0 #c7d0df, inset -0.8px 0 0 #c7d0df, inset 0 0.8px 0 #c7d0df,
                    inset 0 -0.8px 0 #c7d0df;
                border-radius: 4px;
                position: relative;
                box-sizing: border-box;

                .item {
                    display: inline-block;
                    width: 102px;
                    height: 28px;
                    color: #3e4558;
                    text-align: center;
                    font-size: 13px;
                    line-height: 28px;
                    cursor: pointer;
                }

                .item.active,
                .item:hover {
                    color: #fafafa;
                    background-color: #3d8bfa;
                    background-position: 50%;
                    z-index: 10;
                }
            }
        }
    }
}

.select-game-content {
    > .plays {
        min-height: 160px;

        .play-nums {
            .play-item:nth-child(2n-1) {
                background-color: #eef2f8;
            }

            .play-item {
                height: 100%;
                padding: 20px;

                .num-bit-contain {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .play-tag > :first-child {
                        z-index: 2;
                        display: block;
                        height: 28px;
                        line-height: 28px;
                        white-space: nowrap;
                        -webkit-transform: translateX(-2px);
                        transform: translateX(-2px);
                        font-size: 13px;
                    }

                    .play-tag {
                        height: auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        z-index: 1;
                        color: #444e57;
                        font-weight: 400;
                        margin-bottom: 6px;

                        .tag-bg {
                            background-image: url(../../images/tagdefaultright.svg);
                            position: absolute;
                            z-index: 0;
                            height: 28px;
                            width: 62px;
                        }
                    }

                    .num-bit {
                        position: relative;
                        margin-left: 16px;
                        min-width: 38px;
                        font-family: Roboto;
                    }

                    .balls_wrap {
                        display: flex;

                        .balls {
                            padding-left: 66px;
                            display: flex;

                            .num.active:before {
                                background: #68a2ff;
                                background-repeat: no-repeat;
                                background-size: contain;
                                border: 0;
                            }

                            .num.active {
                                color: white;
                            }

                            .num + .num {
                                margin-left: 10px;
                            }

                            .num {
                                cursor: pointer;
                                box-sizing: border-box;
                                width: 40px;
                                height: 40px;
                                text-align: center;
                                border-radius: 50%;
                                float: left;
                                font-size: 20px;
                                position: relative;
                                z-index: 2;
                                color: #3e4558;
                                background: #fafafa;
                                background-repeat: no-repeat;
                                background-size: contain;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                &:before {
                                    box-sizing: border-box;
                                    position: absolute;
                                    display: block;
                                    content: '';
                                    background: linear-gradient(180deg, #fafafa, #eef3f8);
                                    border: 0.8px solid #b7bec9;
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                    z-index: 2;
                                }

                                div {
                                    position: relative;
                                    z-index: 10;
                                }

                                &:after {
                                    box-sizing: border-box;
                                    position: absolute;
                                    display: block;
                                    content: '';
                                    bottom: -4px;
                                    width: 34px;
                                    height: 8px;
                                    background: url(../../images/bong_mo_so.png);
                                    background-size: contain;
                                    z-index: 1;
                                }
                            }
                        }
                    }

                    .opts {
                        .opt-item {
                            width: 50px;
                            text-align: center;
                            height: 26px;
                            box-sizing: border-box;
                            line-height: 26px;
                            background: #fbfbfb;
                            border-radius: 4px;
                            margin-left: 5px;
                            color: #444e57;
                            font-size: 13px;
                            cursor: pointer;
                            border: 0.8px solid #aac1e5;
                        }
                    }
                }
            }
        }
    }
}

.bet-container {
    overflow: auto;
    color: #4d4d4d;

    .bet-input {
        padding: 0 20px;
        box-shadow: inset 0 1px 0 #e2e5ec;
        height: 68px;
        font-size: 13px;
        color: #4c4c4c;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bet-info {
            display: flex;
            align-items: center;

            .input-odds {
                height: 28px;
                line-height: 28px;
                padding: 0 9px;
                background: #e7f0fc;
                border: 0.8px solid #3a81e5;
                border-radius: 4px;
            }

            span {
                color: #3a81e5;
                font-weight: 500;
                font-size: 1.17em;
            }
        }

        .bet-btns {
            display: flex;
            position: relative;
            align-items: center;
            height: 30px;

            .reset {
                height: 30px;
                min-width: 69.9px;
                line-height: 32px;
                text-align: center;
                background-image: url(../../images/btnResetDefault.png);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50%;
                color: #3e4558;
                border-radius: 5px;
                padding: 0px 15px;
                cursor: pointer;
                margin-left: 25px;
            }
        }

        .bet-btn.btn-add {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            background-image: url(../../images/bgdacam.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50%;
            min-width: 69.9px;
            height: 30px;
        }
    }

    .bets {
        overflow: auto;

        .bets-box {
            width: 1062px;
            background: #fafafa url(../../images/betboxbg.png) no-repeat;
            background-size: 100% auto;
            text-align: center;
            padding-left: 6px;
            padding-right: 6px;
            box-sizing: border-box;
            position: relative;
            margin: 0 auto;
            padding-top: 4px;

            .table-title {
                font-size: 15px;
                height: 36px;
                line-height: 36px;
                color: #444e57;
                border-left: 1px solid #c7d0df;
                border-right: 1px solid #c7d0df;
                background: linear-gradient(180deg, #e2e5ec, #fff 1.92%, #fafafa 3.79%);
                border-top-right-radius: 4px;
                border-top-left-radius: 4px;
                font-weight: 300;
            }

            .table-name {
                border-left: 1px solid #c7d0df;
                border-right: 1px solid #c7d0df;
                background: #eef2f8;
            }

            .table-name.content-table {
                background-color: #fff;
            }

            .item {
                width: 100%;
                margin: 0 !important;
                height: 37px;
                line-height: 37px;
                font-size: 13px;
                padding: 0 3px;
                box-sizing: border-box;
            }

            .b-content {
                height: 118px;
                overflow-y: auto;
                border-left: 1px solid #c7d0df;
                border-right: 1px solid #c7d0df;
                border-bottom: 1px solid #c7d0df;
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;
                background-color: #fafafa;

                .no-data {
                    margin-top: 50px;
                }
            }
        }
    }

    .bet-btns {
        .bet-btn {
            width: 120px;
            height: 38px;
            line-height: 38px;
            margin-left: 15px;
            border-radius: 4px;
            text-align: center;
            color: #fafafa;
            cursor: pointer;
            font-size: 16px;
            transition: all 0.28s;
            position: relative;
        }

        span {
            color: #ea524d;
        }

        .bet-btn.btn-detel {
            background-image: url(../../images/bgdacam.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50%;
        }

        .bet-btn.btn-submit {
            background-image: url(../../images/bgxanhduong.png);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50%;
        }
    }
}

.basic-slide {
    display: inline-block;
    width: 150px;
    padding: 10px 0 10px 15px;
    font-family: 'Open Sans', sans;
    font-weight: 500;
    color: #3a81e5;
    background: #efefef;
    border: 0;
    border-radius: 3px;
    outline: 0;
    text-indent: 90px; // Arbitrary.
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    margin-right: 20px;
    height: 30px;

    &::-webkit-input-placeholder {
        color: #efefef;
        text-indent: 0;
        font-weight: 300;
    }

    + label {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        padding: 5px 15px;
        text-shadow: 0 1px 0 rgba(19, 74, 70, 0.4);
        background: #3a81e5;
        transition: all 0.3s ease-in-out;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        color: white;
        font-size: 14px;
    }
}
.basic-slide:focus,
.basic-slide:active {
    color: #3a81e5;
    text-indent: 80px;
    background: #fff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &::-webkit-input-placeholder {
        color: #aaa;
    }
    + label {
        transform: translateX(-30%);
    }
}

.game-history {
    width: 280px;
    margin: 10px auto 12px;
    box-sizing: border-box;
    position: relative;

    .result-web {
        height: 42px;
        background-color: #68a2ff;
        margin-bottom: 10px;
        border-radius: 6px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 28px;
        }
    }

    .h-select .el-select {
        user-select: none;

        .el-input--suffix {
            position: relative;
            margin-bottom: 10px;
            cursor: pointer;

            input {
                cursor: pointer;
                user-select: none;
            }

            img {
                position: absolute;
                top: 8px;
                right: 10px;
            }
        }

        .el-input__inner {
            background: #eef2f8;
            border-radius: 2px;
            border: 0;
            color: #3a81e5;
            font-weight: 600;
            padding-left: 10px;
            text-align: center;
            height: 36px;
            line-height: 36px;
            font-family: Roboto;
            width: 100%;
            font-size: 16px;
        }
    }
}

.history-vncp {
    color: #3e4558;
    font-family: Roboto;

    .cur-item {
        height: 34px;
        line-height: 34px;
        font-size: 13px;
        color: #3e4558;
        padding-left: 11px;
        background: #eef2f8;
        text-align: center;
        font-weight: 600;
        border-top-right-radius: 2px;
        border-top-left-radius: 2px;
    }

    .list.results1 {
        margin-bottom: 10px;
        border-radius: 6px;
        box-shadow: 0 2px 8px rgba(187, 194, 211, 0.2);

        li {
            display: flex;
            align-items: center;
            padding: 0;
            background: #fbfbfb;
            line-height: 1;
            border-bottom: 1px solid rgb(228, 228, 228);
            word-wrap: break-word;
            word-break: break-all;

            > div:first-child {
                padding: 10px 5px;
                width: 100px;
                background: #f0f3f9;
            }

            > div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: auto;
                line-height: 22px;
                padding: 0px 18px;
            }
        }
    }

    .list.results2 {
        word-wrap: break-word;
        box-shadow: inset 0 -0.5px 0 #f3f5fa;
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 0 2px 8px rgba(187, 194, 211, 0.2);

        .title {
            background: #eef2f8;
            align-items: center;
        }

        li {
            display: flex;
            align-items: center;
            padding: 0;
            background: #fbfbfb;
            line-height: 1;
            border-bottom: 1px solid rgb(228, 228, 228);

            div:first-child {
                padding: 10px 15px;
                width: 100px;
                background: #f0f3f9;
            }

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
            }
        }
    }

    .list {
        background-color: #fafafa;
        font-weight: 400;
        font-size: 14px;

        li {
            display: flex;
            box-shadow: inset 0 -0.5px 0 #f3f5fa;
            text-align: center;
            font-weight: 400;
            font-size: 14px;
            box-sizing: border-box;
        }
    }
}

.betlog-wrap {
    margin-top: 10px;
    width: 1400px;
    background-color: #fff;
    border-radius: 12px;

    .bet-log {
        width: 100%;
        min-height: 250px;
        border: 1px solid #e6e6e6;
        background-size: 100% 100%;
        text-align: center;
        color: #4d4d4d;
        box-sizing: border-box;
        background-color: #fff;

        .table-title {
            font-size: 15px;
            height: 46px;
            line-height: 46px;
            position: relative;
            font-size: 14px;

            .more {
                position: absolute;
                left: 30px;
            }

            .bet-tabs {
                position: absolute;
                right: 10px;
                top: 9px;
                font-size: 12px;

                .game-tabs {
                    border: 1px solid rgb(219, 219, 219);
                    border-radius: 4px;
                }

                .item-warp {
                    background: #f4f6fb;
                    border-radius: 4px;

                    .item-tab-history,
                    .item-tab-winlose {
                        border-right: 1px solid rgb(219, 219, 219);
                    }

                    .item-tab {
                        width: 120px;
                        text-align: center;
                        float: left;
                        display: inherit;
                        height: 26px;
                        line-height: 26px;
                        font-size: 13px;
                        cursor: pointer;
                    }

                    .item-tab.active {
                        color: #fafafa;
                        background-image: url(../../images/bgnutbluenhat.png);
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: 50%;
                    }
                }

                .b-btn {
                    height: 26px;
                    line-height: 26px;
                    width: 80px;
                    margin-left: 10px;
                    border-radius: 2px;
                    cursor: pointer;
                }
            }
        }

        .item {
            height: 28px;
            line-height: 28px;
            font-size: 13px;
        }

        .table-name {
            height: 30px;
            line-height: 30px;
            background: #eef2f8;

            div {
                border-right: 1px solid rgb(204, 204, 204);
            }
        }

        .table-name.content-table {
            background-color: #fff;
            margin-left: 1px;
            width: 100%;

            div {
                border: none;
            }
        }

        .b-content {
            height: 150px;

            .no-data {
                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    color: #ccc;
                    line-height: 200px;
                }
            }
        }

        .el-pagination {
            white-space: nowrap;
            padding: 2px 5px;
            color: #303133;
            font-weight: 700;
            margin: 10px;
            display: flex;
            justify-content: center;

            button:disabled {
                cursor: not-allowed;
            }

            .btn-prev,
            .btn-next {
                margin: 0 5px;
                background-color: #f4f4f5;
                color: #606266;
                min-width: 30px;
                border-radius: 2px;
            }

            .el-pager {
                li {
                    margin: 0 5px;
                    background-color: #f4f4f5;
                    color: #606266;
                    min-width: 30px;
                    border-radius: 2px;
                    padding: 0 4px;
                    font-size: 13px;
                    min-width: 35.5px;
                    height: 28px;
                    line-height: 28px;
                    box-sizing: border-box;
                    text-align: center;
                    cursor: pointer;
                }

                .active {
                    background-color: #409eff;
                    color: #fff;
                }
            }
        }
    }
}

.play-textarea {
    margin: 0 20px;
    position: relative;

    .textarea-warp {
        width: 100%;
        background: #fff;
        box-shadow: 0.5px 0.5px 0 #d6e2f4, -0.5px -0.5px 0 #d6e2f4, -0.5px 0.5px 0 #d6e2f4, 0.5px -0.5px 0 #d6e2f4;
        border-radius: 8px;
        margin-bottom: 10px;
        z-index: 2;
        border: 1px solid rgb(228, 228, 228);

        textarea {
            border: 0;
            border-radius: 4px;
            padding: 0.7em;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            position: relative;
            z-index: 2;
            background: transparent;
            margin: 0;
            outline: none;
            resize: none;
            font-weight: 400;
        }
    }

    .textarea-warp:focus + .play-explain,
    .play-explain.hide {
        display: none;
    }

    .play-explain {
        position: absolute;
        left: 1em;

        right: 1em;
        top: 1em;
        color: #3e4558;
        line-height: 1.5;
        z-index: 1;
        font-size: 1em;
        font-weight: 500;
        cursor: text;
    }
}

.el-select-dropdown {
    min-width: 280px;
    transform-origin: center top;
    z-index: 2027;
    position: absolute;
    top: 90px;
    left: 0px;
    margin-top: 12px;
    -webkit-transform: translateZ(0) !important;
    transform: translateZ(0) !important;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    margin: 5px 0;

    .el-scrollbar {
        overflow: hidden;
        position: relative;

        .el-select-dropdown__wrap {
            margin-bottom: -17px;
            margin-right: -17px;
            overflow-y: scroll;
            height: 100%;
            max-height: 274px;

            .el-select-dropdown__list {
                list-style: none;
                padding: 6px 0;
                margin: 0;
                box-sizing: border-box;
                overflow: auto;

                .el-select-dropdown__item.selected {
                    color: #d63e35;
                    font-weight: 600;
                }

                .el-select-dropdown__item {
                    font-size: 14px;
                    padding: 0 20px;
                    position: relative;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #606266;
                    height: 34px;
                    line-height: 34px;
                    box-sizing: border-box;
                    cursor: pointer;
                    text-align: center;

                    &:hover {
                        background-color: #f5f7fa;
                    }
                }
            }
        }
    }

    .popper__arrow {
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #fff;
        border-top: 6px solid transparent;
        position: absolute;
        top: -12px;
        left: 20px;
    }
}

.bg-game {
    position: relative;
}
